.projects {
  margin-top: 4rem;
  background-color: black;
}

.projects img {
  height: 100%;
  width: 100%;
}

.projects h3 {
  text-align: center;
  color: white;
}

.projects a {
  color: white;
}

.project a:hover {
  text-decoration: none;
}

.project {
  border: 2px solid #0b233f;
  border-radius: 5px;
  border-width: 2px;
  margin-top: 1rem;
}

.project-title {
  font-size: 1.5rem;
}

.project:hover {
  border: 2px solid white;
  border-radius: 5px;
  border-width: 2px;
}
