.grid-container {
  display: grid;
  padding-top: 55px;
  grid-template-columns: 16.666666666% 16.666666666% 16.666666666% 16.666666666% 16.666666666% 16.666666666%;
}
.grid-item {
  font-size: 30px;
  text-align: center;
  max-width: 100%;
  height: auto;
}

.grid-item img {
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 1275px) {
  .grid-container {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 1020px) {
  .grid-container {
    grid-template-columns: 25% 25% 25% 25%;
  }
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 765px) {
  .grid-container {
    grid-template-columns: 33.3333333% 33.3333333% 33.3333333%;
  }
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 510px) {
  .grid-container {
    grid-template-columns: 50% 50%;
  }
}
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 255px) {
  .grid-container {
    grid-template-columns: 100%;
  }
}
