body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.MuiDrawer-paperAnchorLeft {
  left: 0;
  right: auto;
  /* margin-top: 64px; */
}

@media (max-width: 600px) {
  .MuiDrawer-paperAnchorLeft {
    /* margin-top: 56px; */
  }  
}
.MuiAppBar-colorPrimary {
  background-color: black
}

.MuiToolbar-root .MuiToolbar-regular .MuiToolbar-gutters {
  padding-left: 0px;
}
.grid-container {
  display: grid;
  padding-top: 55px;
  grid-template-columns: 16.666666666% 16.666666666% 16.666666666% 16.666666666% 16.666666666% 16.666666666%;
}
.grid-item {
  font-size: 30px;
  text-align: center;
  max-width: 100%;
  height: auto;
}

.grid-item img {
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 1275px) {
  .grid-container {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 1020px) {
  .grid-container {
    grid-template-columns: 25% 25% 25% 25%;
  }
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 765px) {
  .grid-container {
    grid-template-columns: 33.3333333% 33.3333333% 33.3333333%;
  }
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 510px) {
  .grid-container {
    grid-template-columns: 50% 50%;
  }
}
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 255px) {
  .grid-container {
    grid-template-columns: 100%;
  }
}

.projects {
  margin-top: 4rem;
  background-color: black;
}

.projects img {
  height: 100%;
  width: 100%;
}

.projects h3 {
  text-align: center;
  color: white;
}

.projects a {
  color: white;
}

.project a:hover {
  text-decoration: none;
}

.project {
  border: 2px solid #0b233f;
  border-radius: 5px;
  border-width: 2px;
  margin-top: 1rem;
}

.project-title {
  font-size: 1.5rem;
}

.project:hover {
  border: 2px solid white;
  border-radius: 5px;
  border-width: 2px;
}

body {
  background-color: black;
  margin-left: 0px;
  margin-right: 0px;
  /*width: calc(80% - 30px);*/
}
h1,
h2,
p,
ul,
li {
  font-family: Georgia;
}

a {
  color: black;
  text-decoration: none;
}

.header {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: black;
  opacity: 0.75;
}

.header-flex-container {
  display: flex;
  width: 50%;
  max-width: calc(800px - (30px * 2));
  margin-right: auto;
  margin-left: auto;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
}

.app-title {
  display: flex;
  padding-top: 12px;
  top: 0;
  left: 0;
}

.app-title img {
  width: 50px;
  height: 37.95px;
}

.app-title h1 {
  font-family: "SF Pro Text", "Myriad Set Pro", "SF Pro Icons", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  text-decoration: none;
  color: white;
  display: inline-block;
}

ul.nav-bar {
  margin: 0px;
  padding: 0px;
  align-content: right;
  display: flex;
  padding-top: 9px;
}

ul.nav-bar a li {
  color: white;
  text-decoration: none;
  margin: 0 0 6px 0;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 12px;
  display: inline-block;
  text-align: left;
  list-style-type: none;
  font-family: "SF Pro Text", "Myriad Set Pro", "SF Pro Icons", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
}

ul.nav-bar a.active li {
  color: #007bff;
}

ul.nav-bar a li:hover {
  color: #a9a9a9;
}

ul.nav-bar a.active li:hover {
  color: #007bff;
}

.app-body {
  display: flex;
  margin-top: 60px;
}

.content {
  background-color: #white;
  display: inline-block;
  max-width: 100%;
  display: block;  
}

.content-block-full {
  max-width: 100%;
}

.content-block-normal {
  max-width: 80%;
  margin-top: 75px;
  margin-bottom: 75px;
}

.fade-in {
  opacity: 1;
  -webkit-animation-name: fadeInOpacity;
          animation-name: fadeInOpacity;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

@-webkit-keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

