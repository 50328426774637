body {
  background-color: black;
  margin-left: 0px;
  margin-right: 0px;
  /*width: calc(80% - 30px);*/
}
h1,
h2,
p,
ul,
li {
  font-family: Georgia;
}

a {
  color: black;
  text-decoration: none;
}

.header {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: black;
  opacity: 0.75;
}

.header-flex-container {
  display: flex;
  width: 50%;
  max-width: calc(800px - (30px * 2));
  margin-right: auto;
  margin-left: auto;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
}

.app-title {
  display: flex;
  padding-top: 12px;
  top: 0;
  left: 0;
}

.app-title img {
  width: 50px;
  height: 37.95px;
}

.app-title h1 {
  font-family: "SF Pro Text", "Myriad Set Pro", "SF Pro Icons", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  text-decoration: none;
  color: white;
  display: inline-block;
}

ul.nav-bar {
  margin: 0px;
  padding: 0px;
  align-content: right;
  display: flex;
  padding-top: 9px;
}

ul.nav-bar a li {
  color: white;
  text-decoration: none;
  margin: 0 0 6px 0;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 12px;
  display: inline-block;
  text-align: left;
  list-style-type: none;
  font-family: "SF Pro Text", "Myriad Set Pro", "SF Pro Icons", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
}

ul.nav-bar a.active li {
  color: #007bff;
}

ul.nav-bar a li:hover {
  color: #a9a9a9;
}

ul.nav-bar a.active li:hover {
  color: #007bff;
}

.app-body {
  display: flex;
  margin-top: 60px;
}

.content {
  background-color: #white;
  display: inline-block;
  max-width: 100%;
  display: block;  
}

.content-block-full {
  max-width: 100%;
}

.content-block-normal {
  max-width: 80%;
  margin-top: 75px;
  margin-bottom: 75px;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
